import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Icon } from 'react-icons-kit';
import { facebook } from 'react-icons-kit/fa/facebook';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Container from 'common/components/UI/Container';
import FooterWrapper, {
  CopyrightText,
  SocialList,
} from './footer.style';

const Footer = ({ row, colTwo }) => {
  return (
    <FooterWrapper>
      <Container width="1260px">
        <Box className="row" {...row}>
          {/* End of logo column */}
          <Box className="col-two" {...colTwo}>
            <Link to="/cookies">Cookie Policy</Link>&nbsp;|&nbsp;<Link to="/privacy">Privacy Policy</Link>
          </Box>
          {/* End of List column */}
        </Box>
        {/* End of widgets row */}
        <Box className="row copyright" {...row}>
          <CopyrightText>
            <Text
              className="text"
              content="© 2021 Lammas Lands Preservation Group. All Rights Reserved"
            />
          </CopyrightText>
          <SocialList>
            <li className="facebook">
              <a
                href="https://www.facebook.com/Lammas-Lands-Preservation-Group-103028008850987"
                aria-label="social share link"
              >
                <Icon icon={facebook} />
              </a>
            </li>
          </SocialList>
        </Box>
        {/* End of copyright row */}
      </Container>
    </FooterWrapper>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // Footer col one style
  colOne: {
    width: ['100%', '30%', '35%', '30%'],
    mt: [0, '13px', '0'],
    mb: ['30px', 0],
    pl: ['15px', 0],
    pr: ['15px', '15px', 0],
  },
  // Footer col two style
  colTwo: {
    width: ['100%', '70%', '65%', '70%'],
    flexBox: true,
    flexWrap: 'wrap',
  },
  // Footer col default style
  col: {
    width: ['100%', '50%', '50%', '33.33%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
};

export default Footer;
