import React, { useState, useContext } from 'react';
import Scrollspy from 'react-scrollspy';
import Drawer from 'common/components/Drawer';
import Image from 'common/components/Image';
import { DrawerContext } from 'common/contexts/DrawerContext';
import InnerWrapper, { SpreadButton } from './drawerSection.style';
import heartImage from 'common/assets/image/charity/heart-red.png';
import { Link } from 'gatsby'

const DrawerSection = () => {
  const [toggleState, setToggleState] = useState(false);
  const { state, dispatch } = useContext(DrawerContext);

  const handleActiveClass = () => {
    setToggleState(!toggleState);
  };

  const handleDrawerToggle = () => {
    dispatch({
      type: 'TOGGLE',
    });
    handleActiveClass();
  };

  const data = {
    charityJson: {
      menuItems: [
        {
          path: "/",
          label: "About",
          offset: "0"
        },
        {
          path: "/wildlife",
          label: "Wildlife",
          offset: "0"
        },
        {
          path: "/flood-defence",
          label: "Flood Defence",
          offset: "0"
        },
        {
          path: "/godalming-greenway",
          label: "Godalming Greenway",
          offset: "0"
        },
        {
          path: "/visitor-demographics",
          label: "Visitor Demographics",
          offset: "0"
        },
        {
          path: "/resources",
          label: "Resources & Studies",
          offset: "0"
        },
      ]
    }
  };

  const scrollItems = [];

  data.charityJson.menuItems.forEach((item) => {
    scrollItems.push(item.path.slice(1));
  });

  return (
    <Drawer
      width="420px"
      placement="right"
      drawerHandler={
        <button
          className={`drawer_btn ${toggleState ? 'active' : ''}`}
          onClick={handleActiveClass}
          aria-label="drawer toggle button"
        >
          <ul className="grid">
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
          </ul>
          <i className="flaticon-plus-symbol " />
        </button>
      }
      open={state.isOpen}
      toggleHandler={handleDrawerToggle}
    >
      <InnerWrapper>
        <Scrollspy
          className="scrollspy__menu"
          items={scrollItems}
          offset={-81}
          currentClassName="active"
        >
          {data.charityJson.menuItems.map((menu, index) => (
            <li key={`menu_key${index}`}>
              <Link
                to={menu.path}
                onClick={handleDrawerToggle}
              >
                {menu.label}
              </Link>
            </li>
          ))}
        </Scrollspy>
        <SpreadButton>
          <span className="text">SPREAD</span>
          <Image src={heartImage} alt="Charity Landing" />
        </SpreadButton>
      </InnerWrapper>
    </Drawer>
  );
};

export default DrawerSection;
