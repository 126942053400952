import React from 'react';
import NavbarWrapper from './navbar.style';

const Navbar = () => {
  return (
    <NavbarWrapper className="navbar" />
  );
};

export default Navbar;
